import { Pipe, PipeTransform } from '@angular/core';
import { format, getDay, setDay } from 'date-fns';

@Pipe({ name: 'weekDays' })
export class WeekDaysPipe implements PipeTransform {
  transform(date: Date): string {
    // check if date is less than a week
    const dateWithoutTime = format(new Date(date), 'MM-dd-yyyy');
    const currentDateWithoutTime = format(new Date(), 'MM-dd-yyyy');

    const differenceInTime = new Date(dateWithoutTime).getTime() - new Date(currentDateWithoutTime).getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    if (differenceInDays >= 0 && differenceInDays < 7) {
      const day = format(new Date(date), 'EEEE');
      const weekDays = [0, 1, 2, 3, 4, 5, 6];
      const lastDaysOfThisWeek = weekDays.splice(0, weekDays.indexOf(getDay(new Date())));
      const selectableWeekDays = weekDays.concat(lastDaysOfThisWeek);

      if (day === format(setDay(new Date(), selectableWeekDays[0]), 'EEEE')) {
        return 'Today';
      } else if (day === format(setDay(new Date(), selectableWeekDays[1]), 'EEEE')) {
        return 'Tomorrow';
      }
      return day;
    } else {
      return format(new Date(date), 'dd MMM');
    }
  }
}
