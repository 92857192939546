import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { cloneDeep } from 'lodash-es';
import { LocalStorageService } from 'ngx-webstorage';
import {
  DEFAULT_LIVE_MATCH_STATUSES,
  DEFAULT_OLD_LIVE_MATCH_STATUSES,
  DEFAULT_OLD_LIVE_MATCH_STATUSES_ABBR,
} from 'src/app/modules/live/defaults/default-live-match-statuses';
import {
  AreaModel,
  FavouritesEventListModel,
  LiveEventByArea,
  LiveFeedModel,
  LiveState,
  LiveUIState,
  MarketModel,
  LiveMatchStatuses,
  LiveMatchTrackerProvider,
} from 'src/app/shared/models/live.model';

function createInitialState(): LiveState {
  return {
    liveFeeds: undefined,
    selectedFeedId: undefined,
    selectedMarkets: undefined,
    selectedAreaInMatchView: undefined,
    selectedLiveEvent: undefined,
    liveViewEnabledByUser: false,
    favouritesEventsList: undefined,
    marketGroupingDetails: undefined,
    liveMatchStatuses: DEFAULT_LIVE_MATCH_STATUSES,
    oldLiveMatchStatuses: DEFAULT_OLD_LIVE_MATCH_STATUSES,
    oldLiveMatchStatusesAbbr: DEFAULT_OLD_LIVE_MATCH_STATUSES_ABBR,
    betGeniusLMT: undefined,
    ui: {
      noLiveFeedData: false,
      notValidEventId: false,
      liveFeedsLoading: false,
      selectedLiveEventLoading: false,
    },
    liveAreaSelectionIds: undefined,
    liveMatchTrackerProvider: LiveMatchTrackerProvider.BetRadar,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'live' })
export class LiveStore extends Store<LiveState> {
  private readonly FAVOURITES_EVENTS_LIST_KEY = 'favouritesEventsList';
  private readonly LIVE_AREA_SELECTION_IDS = 'liveAreaSelectionIds';

  constructor(private readonly localStorage: LocalStorageService) {
    super(createInitialState());

    this.updateFavouritesEventsList(this.localStorage.retrieve(this.FAVOURITES_EVENTS_LIST_KEY));
    this.updateLiveAreaSelectionIds(this.localStorage.retrieve(this.LIVE_AREA_SELECTION_IDS));
  }

  updateUI(ui: Partial<LiveUIState>): void {
    this.update(state => ({ ui: { ...state.ui, ...ui } }));
  }

  updateLiveFeed(liveFeeds: LiveFeedModel[]): void {
    this.update({ liveFeeds });
  }

  updateSelectedFeedId(selectedFeedId: number): void {
    this.update({ selectedFeedId });
  }

  updateSelectedLiveEvent(selectedLiveEvent: LiveEventByArea): void {
    this.update({ selectedLiveEvent });
  }

  updateSelectedAreaInMatchView(selectedAreaInMatchView: AreaModel): void {
    this.update({ selectedAreaInMatchView });
  }

  clearSelectedAreaInMatchView(): void {
    this.update({
      selectedAreaInMatchView: undefined,
    });
  }

  updateSelectedMarkets(selectedMarkets: MarketModel[]): void {
    this.update({ selectedMarkets });
  }

  updateLiveViewEnabledByUser(liveViewEnabledByUser: boolean): void {
    this.update({ liveViewEnabledByUser });
  }

  updateFavouritesEventsList(favouritesEventsList: FavouritesEventListModel[]): void {
    this.update({ favouritesEventsList });

    this.localStorage.store(this.FAVOURITES_EVENTS_LIST_KEY, favouritesEventsList);
  }

  updateLiveMatchStatuses(liveMatchStatuses: LiveMatchStatuses): void {
    this.update({ liveMatchStatuses });
  }

  updateLiveAreaSelectionIds(liveAreaSelectionIds: object): void {
    this.update(state => {
      const liveAreaSelectionIdsMerge = { ...state.liveAreaSelectionIds, ...liveAreaSelectionIds };
      this.localStorage.store(this.LIVE_AREA_SELECTION_IDS, liveAreaSelectionIdsMerge);
      return { liveAreaSelectionIds: liveAreaSelectionIdsMerge };
    });
  }

  updateLiveMatchTrackerProvider(liveMatchTrackerProvider: LiveMatchTrackerProvider): void {
    this.update({ liveMatchTrackerProvider });
  }

  updateBetGeniusLMT(betGeniusLMT: string | any): void {
    this.update({ betGeniusLMT });
  }

  removeLiveAreaSelectionIds(selectionId: number): void {
    this.update(state => {
      const liveAreaSelectionIds = cloneDeep(state.liveAreaSelectionIds);
      delete liveAreaSelectionIds[selectionId];
      this.localStorage.store(this.LIVE_AREA_SELECTION_IDS, liveAreaSelectionIds);
      return { liveAreaSelectionIds };
    });
  }
}
