import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import Hammer from '@egjs/hammerjs';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ApplicationService } from 'src/app/core/services/application.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { RegistrationQuery } from 'src/app/core/state/registration/registration.query';
import { SportQuery } from 'src/app/core/state/sport/sport.query';
import { MetaService } from 'src/app/modules/meta/meta.module';
import { slideInToggle, slideUp } from 'src/app/shared/animations';
import { RegistrationType } from 'src/app/shared/models/registration.model';
import { VirtualsInstantService } from 'src/app/core/services/virtuals-instant.service';
import { VirtualsQuery } from 'src/app/core/state/virtuals/virtuals.query';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
  animations: [slideUp(), slideInToggle()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WrapperComponent implements OnInit, OnDestroy {
  lastOrientation: string | number = window.orientation;
  lastWidth: number = window.innerWidth;
  lastHeight: number = window.innerHeight;
  destroy$ = new Subject<boolean>();
  seoFooterText: string | undefined;
  readonly RegistrationType: typeof RegistrationType = RegistrationType;
  readonly showLoginDialog$ = this.applicationQuery.showLoginDialog$;
  readonly showCasinoXSell$ = new BehaviorSubject(false);

  constructor(
    readonly accountQuery: AccountQuery,
    readonly applicationQuery: ApplicationQuery,
    readonly registrationQuery: RegistrationQuery,
    readonly sportQuery: SportQuery,
    readonly virtualsQuery: VirtualsQuery,
    private readonly applicationService: ApplicationService,
    private readonly metaService: MetaService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router,
    private readonly virtualsInstantService: VirtualsInstantService
  ) {}

  @ViewChild('overlayContent')
  set registerOverlayGestures(overlayContent: ElementRef) {
    // register gesture event listeners on the overlay
    if (overlayContent) {
      const hammer = new Hammer(overlayContent.nativeElement);
      hammer.on('tap', () => {
        this.closeMiniSlideUp();
      });
    }
  }

  ngOnInit(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const urlSplit = event.url.split('/');
      urlSplit.shift();
      this.applicationService.updateActiveUrl(urlSplit);
      this.virtualsInstantService.manageWebSocketConnection(urlSplit);
      this.metaService.useDataFromCMS(event, (text: string) => {
        this.seoFooterText = text;
        this.changeDetectorRef.detectChanges();
      });
    });
  }

  loginDialogClosed(): void {
    this.applicationService.setShowLoginDialog(false);
  }

  closeMiniSlideUp(): void {
    this.applicationService.showQuickCoupon(false);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
