import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CurrencyService } from 'src/app/core/services/currency.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { VirtualsQuery } from 'src/app/core/state/virtuals/virtuals.query';
import { Observable, interval, BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { VirtualsJackpot, VirtualsJackpotBanner, VirtualsJackpotWithSlug } from 'src/app/shared/models/virtuals.model';
import { startWith, tap, takeUntil, map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { slowFadeInOut } from 'src/app/shared/animations';
import { VirtualsService } from 'src/app/core/services/virtuals.service';
import { JackpotEngineService } from 'src/app/core/services/jackpot-engine.service';
import { JackpotEngineMapping } from 'src/app/shared/models/jackpot-engine.model';

@Component({
  selector: 'virtuals-jackpot-strip',
  templateUrl: './virtuals-jackpot-strip.component.html',
  styleUrls: ['./virtuals-jackpot-strip.component.scss'],
  animations: [slowFadeInOut()],
})
export class VirtualsJackpotStripComponent implements OnInit, OnDestroy {
  readonly matchedJackpots$ = new BehaviorSubject<VirtualsJackpot[]>([]);
  readonly currentJackpot$ = new BehaviorSubject<VirtualsJackpotWithSlug | null>(null);
  readonly bannerMessage$ = new BehaviorSubject<string>(null);
  alternateJackpot$: Observable<VirtualsJackpot>;
  private jackpotIds: number[] = [];
  private currentJackpotIndex = 0;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly router: Router,
    public virtualsQuery: VirtualsQuery,
    public applicationQuery: ApplicationQuery,
    public currencyService: CurrencyService,
    public appService: AppConfigService,
    private readonly virtualsService: VirtualsService,
    private readonly jackpotEngineService: JackpotEngineService
  ) {}

  ngOnInit(): void {
    forkJoin({
      jackpotBanner: this.virtualsService.getJackpotBanner(),
      jackpots: this.virtualsService.getJackpots(),
      jackpotMappings: this.jackpotEngineService.getJPEngineMapping(),
    })
      .pipe(
        map(({ jackpotBanner, jackpots, jackpotMappings }) => {
          this.bannerMessage$.next(jackpotBanner.bannerMessage);
          const bannerIds = this.extractJackpotIds(jackpotBanner);
          const matchedJackpots = jackpots.filter(jackpot => bannerIds.includes(jackpot.id.toString()));
          this.matchedJackpots$.next(matchedJackpots);
          if (matchedJackpots.length) {
            this.initializeJackpotRotation(jackpotMappings, matchedJackpots);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private initializeJackpotRotation(jackpotMappings: JackpotEngineMapping[], matchedJackpots: VirtualsJackpot[]): void {
    this.jackpotIds = matchedJackpots.map(jackpot => jackpot.id);

    const jackpotMappingDict = matchedJackpots.reduce((dict, jackpot) => {
      const mapping = jackpotMappings.find(jMapping => parseInt(jMapping.jackpotId) === jackpot.id);
      if (mapping) {
        dict[jackpot.id] = { name: mapping.jackpotDisplayName, slug: mapping.jackpotSlug };
      }
      return dict;
    }, {});

    this.setupJackpotRotation(jackpotMappingDict);
  }

  private extractJackpotIds(jackpotBanner: VirtualsJackpotBanner): string[] {
    return jackpotBanner.jackpots.map(jackpot => jackpot.jackpotId);
  }

  private setupJackpotRotation(jackpotMappingDict: Record<number, { name: string; slug: string }>): void {
    interval(this.appService.get('virtuals').jackpot.jackpotsRotationInterval)
      .pipe(
        startWith(0),
        tap(() => this.rotateJackpot(jackpotMappingDict)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private rotateJackpot(jackpotMappingDict: Record<number, { name: string; slug: string }>): void {
    const currentJackpotId = this.getNextJackpotId();
    this.displayCurrentJackpot(currentJackpotId, jackpotMappingDict);
  }

  private getNextJackpotId(): number {
    return this.jackpotIds[(this.currentJackpotIndex = (this.currentJackpotIndex + 1) % this.jackpotIds.length)];
  }

  private displayCurrentJackpot(jackpotId: number, jackpotMappingDict: Record<number, { name: string; slug: string }>): void {
    this.virtualsQuery
      .jackpotById$(jackpotId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(jackpot => {
        if (jackpot) {
          const mapping = jackpotMappingDict[jackpotId];
          if (mapping && mapping.name) {
            jackpot.name = mapping.name;
          }
          this.setCurrentJackpot(jackpot, mapping ? mapping.slug : '');
        }
      });
  }

  private setCurrentJackpot(jackpot: VirtualsJackpot, slug: string): void {
    this.currentJackpot$.next({ ...jackpot, slug });
  }

  navigateToJackpotInfo(): void {
    if (this.applicationQuery.isBrand(['Nigeria'])) {
      this.router.navigateByUrl('latest-jackpots');
    } else {
      this.router.navigateByUrl('latest-mkwanja-virtual-bonus');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
