<div class="odds-table-container" *ngIf="eventsInfo">
  <ng-container *ngIf="eventsInfo.marketSelected?.selections?.length >= 4">
    <!-- Multi-line -->
    <app-multi-line-event
      *ngFor="let event of eventsInfo.matches; trackBy: eventTrackBy"
      [event]="event"
      [market]="eventsInfo.marketSelected"
      [sportId]="eventsInfo.sportId"
    ></app-multi-line-event>
  </ng-container>
  <ng-container *ngIf="eventsInfo.marketSelected?.selections?.length < 4">
    <!-- Single-line -->
    <div class="markets-header">
      <app-events-markets-header
      appStickyTab
      [zIndex]="stickyZIndex"
      [infoLine]="true"
      [haveHeader]="true"
      [showMarketName]="showMarketName"
      [marketName]="eventsInfo.marketSelected?.name"
      [markets]="eventsInfo.marketSelected?.selections"
      [groupedMarkets]="eventsInfo.marketSelected?.groupedMarket"
      [selectedOption]="selectedOption"
      (optionSelected)="groupedMarketSelected($event)">
    </app-events-markets-header>
    </div>
    <div class="event-container">
      <ng-container *ngIf="sortable; else nonSortable">
        <app-single-line-event
        *ngFor="let event of eventsInfo.matches | sortTodaysEventsByOdd: (landingQuery.sortType$ | async)?.value : eventsInfo?.marketSelected; trackBy: eventTrackBy; let last = last; let index = index"
        [event]="event"
        [itemIndex]="index"
        [sportId]="eventsInfo.sportId"
        [last]="last"
        [selectedMarket]="eventsInfo.marketSelected"
        [groupedMarkets]="eventsInfo.marketSelected?.groupedMarket"
        [selectedOption]="selectedOption"
        [eventLocation]="'Homepage_'+ eventLocation +'_'+ eventsInfo.marketSelected?.name + (event.eventCategory === 'L' ? '_Live' : '')"
        [isNewLiveWidget]="isNewLiveWidget"
      ></app-single-line-event>
      </ng-container>
    </div>
    <ng-template #nonSortable>
      <app-single-line-event
        *ngFor="let event of eventsInfo.matches; trackBy: eventTrackBy; let last = last; let index = index"
        [event]="event"
        [itemIndex]="index"
        [sportId]="eventsInfo.sportId"
        [last]="last"
        [selectedMarket]="eventsInfo.marketSelected"
        [groupedMarkets]="eventsInfo.marketSelected?.groupedMarket"
        [selectedOption]="selectedOption"
        [eventLocation]="'Homepage_'+ eventLocation +'_'+ eventsInfo.marketSelected?.name + (event.eventCategory === 'L' ? '_Live' : '')"
        [isNewLiveWidget]="isNewLiveWidget"
      ></app-single-line-event>
    </ng-template>
  </ng-container>
</div>
