import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LandingQuery } from 'src/app/core/state/landing/landing.query';

import { EventSummaryModel, MarketModel, MatchModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-events-summary',
  templateUrl: './events-summary.component.html',
  styleUrls: ['./events-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsSummaryComponent {
  @Input() eventsInfo: EventSummaryModel;
  @Input() showMarketName: boolean = false;
  @Input() eventLocation: string = undefined; // Used for data layer events
  @Input() sortable?: boolean = false;
  @Input() selectedOption: MarketModel;
  @Input() isNewLiveWidget: boolean = false;
  @Input() stickyZIndex: number = 0;

  @Output() readonly optionSelected = new EventEmitter<MarketModel>();

  constructor(readonly landingQuery: LandingQuery) {}

  eventTrackBy(index: number, match: MatchModel): number {
    return match.id;
  }

  groupedMarketSelected(event: MarketModel) {
    this.selectedOption = { ...event };
    this.optionSelected.emit(event);
  }
}
