import { Injectable } from '@angular/core';

import { Store, StoreConfig } from '@datorama/akita';

import { JackpotEngineMapping, JackpotEngineModel } from 'src/app/shared/models/jackpot-engine.model';

function createInitialState(): JackpotEngineModel {
  return {
    jackpotEngineMappings: undefined,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'jackpot-engine' })
export class JackpotEngineStore extends Store<JackpotEngineModel> {
  constructor() {
    super(createInitialState());
  }

  updateJackpotEngineMapping(jackpotEngineMappings: JackpotEngineMapping[]): void {
    this.update({ jackpotEngineMappings });
  }
}
