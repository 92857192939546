<div class="event">
  <ng-container *ngIf="showDetails">
    <div class="top">
      <app-event-tournament-details [event]="event" [sportId]="sportId" *ngIf="sportId">
      </app-event-tournament-details>
      <app-event-details [event]="event" *ngIf="showDetails"
        (click)="matchService.navigateToMatch(event.id, event.name)">
      </app-event-details>
    </div>
    <div
      [@expandCollapse]="{ value: (statisticsQuery.expandedStatistics$ | async) ? 'expanded' : 'collapsed', params: { minHeight: '0px' }}">
      <app-statistics *ngIf="event.externalId === (statisticsQuery.selectedExternalId$ | async)"
        [externalId]="event.externalId"></app-statistics>
    </div>
  </ng-container>
  <app-correct-scores-market-odds [odd]="event.correctScoreOdds"></app-correct-scores-market-odds>
</div>