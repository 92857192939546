import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { VariablesService } from 'src/app/core/services/variables.service';
import { expandCollapse } from 'src/app/shared/animations';

@Component({
  selector: 'app-card',
  templateUrl: './app-card.component.html',
  styleUrls: ['./app-card.component.scss'],
  animations: [expandCollapse()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent implements OnInit {
  @Input() titleHeaderText: string;
  @Input() rightIcon: string;
  @Input() loading: boolean;
  @Input() collapsible = false;
  @Input() addOverflow = true;
  @Input() leftSportIconId: number = undefined;
  @Input() leftSvgIcon: string = undefined;
  @Input() leftSvgIconWidth: string = 'auto';
  @Input() leftSvgIconHeight: string = 'auto';
  @Input() addBorderRadius: boolean = true;
  @Input() addHorizontalLine = false; // this horizontal line can be used when we have a parent app card
  @Input() overrideHeaderPadding = false;
  @Input() overrideCardMargin = false;

  collapsed = false;

  constructor(public variablesService: VariablesService) {}

  ngOnInit(): void {
    if (!this.rightIcon && this.collapsible) {
      this.rightIcon = 'fa fa-caret-right';
    }
  }

  toggleWidget(): void {
    if (this.collapsible) {
      this.collapsed = !this.collapsed;
    }
  }
}
