import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { oddChange } from 'src/app/shared/animations';
import { OddModel } from 'src/app/shared/models/coupon.model';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { BetslipActions, DataLayerProduct } from 'src/app/shared/models/datalayer.model';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
@Component({
  selector: 'app-live-odd',
  templateUrl: './live-odd.component.html',
  styleUrls: ['./live-odd.component.scss'],
  animations: [oddChange()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiveOddComponent implements OnInit, OnDestroy {
  @Input() odd: OddModel;
  @Input() eventLocation: string = undefined; // Used for data layer events
  @Input() matchPosition: string = undefined; // Used for data layer events
  @HostBinding('class.single') @Input() single = false;
  selected$ = new BehaviorSubject<boolean>(false);

  private readonly destroy$ = new Subject<any>();

  constructor(
    private readonly couponQuery: CouponQuery,
    private readonly accountQuery: AccountQuery,
    private readonly couponService: CouponService,
    private readonly dataLayerService: DataLayerService
  ) {}

  ngOnInit(): void {
    this.couponQuery.couponData$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const selected = this.odd && this.couponService.isOddInCoupon(this.odd.id);
      this.selected$.next(selected);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectOdd(): void {
    if (this.selected$.value) {
      this.couponService.removeOdd(this.odd.id, this.odd.marketId);
    } else {
      this.addEventToDataLayer();
      this.couponService.addOdd(this.odd);
    }
  }

  private addEventToDataLayer(): void {
    const { eventLocation } = this;
    this.dataLayerService.createDataLayerEvent({
      event: BetslipActions.AddToBetslip,
      user_id: this.accountQuery.userData?.id,
      product: DataLayerProduct.SportsBookLive,
      selection_id: this.odd.id,
      location: eventLocation ? eventLocation : window.location.pathname,
      position: this.matchPosition ? this.matchPosition : undefined,
    });
  }
}
