import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { LandingStore } from 'src/app/core/state/landing/landing.store';
import { SortFilterOptionModel } from 'src/app/shared/models/filter-sort.model';
import { LandingState, WidgetInfoModel, WidgetTypes } from 'src/app/shared/models/landing.model';
import { MenuItemModel } from 'src/app/shared/models/menu.model';
import { EventSummaryModel, MarketModel, MatchModel } from 'src/app/shared/models/sport.model';

@Injectable({
  providedIn: 'root',
})
export class LandingQuery extends Query<LandingState> {
  // UI
  liveBettingEventsLoading$ = this.select(state => state.ui.liveBettingEventsLoading);
  liveBettingNewEventsLoading$ = this.select(state => state.ui.liveBettingNewEventsLoading);
  betBuilderEventsLoading$ = this.select(state => state.ui.betBuilderEventsLoading);
  mostPopularEventsLoading$ = this.select(state => state.ui.mostPopularEventsLoading);
  upcomingEventsLoading$ = this.select(state => state.ui.upcomingEventsLoading);
  todayEventsLoading$ = this.select(state => state.ui.todayEventsLoading);
  oddsBoostEventsLoading$ = this.select(state => state.ui.oddsBoostEventsLoading);
  menuExpanded$ = this.select(state => state.ui.menuExpanded);

  // Widgets Info
  widgetsInfo$ = this.select(state => state.widgetsInfo);
  liveBettingInfo$ = this.select(state =>
    state.widgetsInfo ? state.widgetsInfo.find(widgetInfo => widgetInfo.type === WidgetTypes.LiveBetting) : undefined
  );
  liveBettingNewInfo$ = this.select(state =>
    state.widgetsInfo ? state.widgetsInfo.find(widgetInfo => widgetInfo.type === WidgetTypes.LiveBettingNew) : undefined
  );
  betBuilderInfo$ = this.select(state =>
    state.widgetsInfo ? state.widgetsInfo.find(widgetInfo => widgetInfo.type === WidgetTypes.BetBuilder) : undefined
  );

  // Data
  liveBettingEvents$ = this.select(state => state.liveBettingEvents);
  liveBettingNewEvents$ = this.select(state => state.liveBettingNewEvents);
  betBuilderEvents$ = this.select(state => state.betBuilderEvents);
  mostPopularEvents$ = this.select(state => state.mostPopularEvents);
  upcomingEvents$ = this.select(state => state.upcomingEvents);
  todayEvents$ = this.select(state => state.todayEvents);
  todaySports$ = this.select(state => state.todaySports);
  oddsBoostEvents$ = this.select(state => state.oddsBoostEvents);
  selectedDate$ = this.select(state => state.selectedDate);
  sortType$ = this.select(state => state.sortType);

  constructor(protected store: LandingStore, private readonly accountQuery: AccountQuery) {
    super(store);
  }

  get menuExpanded(): boolean {
    return this.getValue().ui.menuExpanded;
  }

  get menuItems$(): Observable<MenuItemModel[]> {
    return this.select(state => state.menuItems).pipe(
      map((mim: MenuItemModel[]) =>
        mim.filter(item => {
          // will be reverted to this line of code once JPB is available for all users
          // (this.accountQuery.isAuthenticated ? item.showWhenLoggedIn : item.showWhenLoggedOut)

          let isEnabled = this.accountQuery.isAuthenticated ? item.showWhenLoggedIn : item.showWhenLoggedOut;

          if (item.title === 'Jackpot Bets') {
            isEnabled = this.accountQuery.isJackpotBetsEnabled && isEnabled;
          }

          return isEnabled;
        })
      )
    );
  }

  get liveBettingInfo(): WidgetInfoModel {
    return this.getValue().widgetsInfo?.find(widgetInfo => widgetInfo.type === WidgetTypes.LiveBetting);
  }

  get liveBettingNewInfo(): WidgetInfoModel {
    return this.getValue().widgetsInfo?.find(widgetInfo => widgetInfo.type === WidgetTypes.LiveBettingNew);
  }

  get betBuilderInfo(): WidgetInfoModel {
    return this.getValue().widgetsInfo?.find(widgetInfo => widgetInfo.type === WidgetTypes.BetBuilder);
  }

  get selectedDate(): SortFilterOptionModel {
    return this.getValue().selectedDate;
  }

  get sortType(): SortFilterOptionModel {
    return this.getValue().sortType;
  }

  isLiveBettingNewDataFeedInitialisedForSport(sportId: number): boolean {
    return this.getValue().liveBettingNewEvents && this.getValue().liveBettingNewEvents.sportId === sportId;
  }

  isMostPopularDataFeedInitialisedForSport(sportId: number): boolean {
    return this.getValue().mostPopularEvents && this.getValue().mostPopularEvents.sportId === sportId;
  }

  isUpcomingDataFeedInitialisedForSport(sportId: number): boolean {
    return this.getValue().upcomingEvents && this.getValue().upcomingEvents.sportId === sportId;
  }

  isTodayDataFeedInitialisedForSport(sportId: number, date: SortFilterOptionModel): boolean {
    return this.getValue().todayEvents && this.getValue().todayEvents.sportId === sportId && this.selectedDate?.value === date?.value;
  }

  isOddsBoostDataFeedInitialisedForSport(sportId: number): boolean {
    return this.getValue().oddsBoostEvents && this.getValue().oddsBoostEvents.sportId === sportId;
  }

  liveBettingEventsSingleMarket$(marketId: number): Observable<EventSummaryModel> {
    return this.liveBettingEvents$.pipe(map(events => this.getSingleMarket(events, marketId)));
  }

  getEventsSingleMarket(
    eventSummary: EventSummaryModel,
    marketId: number,
    spreadValue: number,
    isCustomMarket: boolean = false
  ): EventSummaryModel {
    return this.getSingleMarket(eventSummary, marketId, spreadValue, isCustomMarket);
  }

  private getSingleMarket(
    events: EventSummaryModel,
    marketId: number,
    spreadValue: number = undefined,
    isCustomMarket: boolean = false
  ): EventSummaryModel {
    let matches: MatchModel[] = [];
    let marketSelected = new MarketModel({});
    if (events) {
      // populate matches according to the market Id
      matches = this.populateMatches(events, marketId, spreadValue);

      if (marketId && matches.length > 0) {
        if (spreadValue) {
          marketSelected = events.area.markets.filter(market => market.typeId === marketId && market.spreadValue === spreadValue)[0];
        } else {
          marketSelected = events.area.markets.filter(market => market.typeId === marketId)[0];
        }
      } else if (!isCustomMarket) {
        marketSelected = events.area.markets[0];

        // populate matches according to the market Id
        matches = this.populateMatches(events, marketSelected.typeId, marketSelected.spreadValue);
      }

      if (!marketSelected) {
        const groupedMarket = events.area.markets.filter(market =>
          market.groupedMarket?.find(t => t.typeId && t.spreadValue === spreadValue)
        )[0].groupedMarket;
        marketSelected = groupedMarket.find(t => t.typeId && t.spreadValue === spreadValue);
        marketSelected.groupedMarket = groupedMarket;
      }

      return new EventSummaryModel({
        ...events,
        matches,
        marketSelected,
      });
    } else {
      return undefined;
    }
  }

  private populateMatches(events: EventSummaryModel, marketId: number, spreadValue: number): MatchModel[] {
    const matches: MatchModel[] = [];
    events.matches.forEach(match => {
      let matchOdds;
      if (spreadValue) {
        matchOdds = match.odds.filter(odd => odd?.marketTypeId === marketId && odd.spreadValue === spreadValue);
      } else {
        matchOdds = match.odds.filter(odd => odd?.marketTypeId === marketId);
      }

      if (matchOdds && matchOdds.length > 0) {
        matches.push(
          new MatchModel({
            ...match,
            odds: matchOdds,
          })
        );
      }
    });

    return matches;
  }
}
