import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appStickyTab]',
})
export class StickyTabDirective {
  @Input() infoLine: boolean = false;
  @Input() haveHeader: boolean = false;
  @Input() zIndex: number = 0;

  private readonly el: ElementRef;
  private lastScrollTop: number;
  private unStickPoint: number | null = null;

  initialTop: number;

  constructor(element: ElementRef) {
    this.el = element;
    this.initialTop = this.el.nativeElement.offsetTop;
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    if (!this.el || !this.el.nativeElement) return false;
    const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
    if (currentScrollTop >= this.lastScrollTop && this.el?.nativeElement.getBoundingClientRect().top <= 56) {
      this.el.nativeElement.classList?.add(`sticky${this.haveHeader ? '' : '-0'}`);
      this.el.nativeElement.style?.setProperty('z-index', 2 + this.zIndex);
      if (document.getElementsByClassName('market-tabs-container')?.length === 0) this.el.nativeElement.classList?.add('no-market-tab');
      if (this.infoLine) {
        this.el.nativeElement.classList?.add('sticky-info-line');
        this.el.nativeElement.parentNode?.nextSibling?.classList?.add('sticky-container');
      }
      if (this.unStickPoint === null) {
        this.unStickPoint = this.lastScrollTop;
      }
    } else if (this.lastScrollTop < this.unStickPoint) {
      this.el.nativeElement.style?.removeProperty('z-index');
      this.el.nativeElement.classList?.remove('sticky');
      this.el.nativeElement.classList?.remove('sticky-0');
      this.el.nativeElement.classList?.remove('sticky-info-line');
      this.el.nativeElement.classList?.remove('no-market-tab');
      this.el.nativeElement.parentNode?.nextSibling?.classList?.remove('sticky-container');
      this.unStickPoint = null;
    }
    this.lastScrollTop = currentScrollTop;
  }
}
