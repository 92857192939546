<ng-container *ngIf="breadcrumb.children | async as breadcrumbChildren; else missingChildren">
  <ng-container *ngIf="breadcrumbChildren && breadcrumbChildren.length > 1; else missingChildren">
    <div class="breadcrumb-navigation-element">
      <div class="breadcrumb-navigation-element-label" (click)="breadcrumbClick()">
        <div class="breadcrumb-navigation-element-label-text">{{ breadcrumb.label }}</div>
        <span class="breadcrumb-navigation-element-label-arrow-icon" data-testid="breadcrumb-navigation-dropdown-arrow">
          <i class="fa" [ngClass]="breadcrumb.id === openedDropdownItem ? 'fa-caret-up' : 'fa-caret-down'"></i>
        </span>
      </div>

      <div
        class="breadcrumb-navigation-element-dropdown"
        data-testid="breadcrumb-navigation-dropdown"
        *ngIf="breadcrumb.id === openedDropdownItem"
        appPositionDropdownOnScreen
      >
        <div
          class="breadcrumb-navigation-element-dropdown-item"
          data-testid="breadcrumb-navigation-dropdown-item"
          *ngFor="let item of breadcrumbChildren"
          (click)="goToPage(item)"
        >
          {{ item.name || item.label }}
        </div>
      </div>
    </div>
    <div *ngIf="breadcrumb.id === openedDropdownItem" class="overlay" (click)="clickedOverlay()"></div>
  </ng-container>
</ng-container>
<ng-template #missingChildren>
  <div>{{ breadcrumb.label }}</div>
</ng-template>

<span *ngIf="!isLastCrumb" class="separator"> / </span>
