<div class="container">
  <div class="pie-container" [class.with-background]="showBackground"
  *ngIf="(remainingTime$ | async) !== undefined
    && (showWhenZero || (remainingTime$ | async) > 0)
    && (remainingTime$ | async) < (pieFullDuration - playoutTimeMs)">
    <span class="countdown-timer" *ngIf="showCountdownText && (remainingTime$ | async) !== undefined">
      {{ remainingTime$ | async | date:'mm:ss' }}
    </span>
    <div class="pie-border">
      <div class="pie"></div>
    </div>
  </div>
  <div class="live-badge" *ngIf="(remainingTime$ | async) > (pieFullDuration - playoutTimeMs) && showLiveFlag">
    <span i18n >LIVE</span>
    <div class="outer-ring"><div class="inner-circle"></div></div>
  </div>
</div>
