import { Injectable } from '@angular/core';
import { isNull, kebabCase } from 'lodash-es';
import { ResolverQuery } from 'src/app/core/state/resolver/resolver.query';
import { ResolverStore } from 'src/app/core/state/resolver/resolver.store';
import { SportQuery } from 'src/app/core/state/sport/sport.query';
import { ResolvedModel, ResolvedObjectModel, ResolverState } from 'src/app/shared/models/resolver.model';
import { SportModel, EventType, Quicklinks } from 'src/app/shared/models/sport.model';
@Injectable({
  providedIn: 'root',
})
export class ResolverService {
  constructor(
    private readonly resolverStore: ResolverStore,
    private readonly resolverQuery: ResolverQuery,
    private readonly sportQuery: SportQuery
  ) {}

  buildCache(prematchData: SportModel[], outrightData: SportModel[], specialData: SportModel[], playerSpecialsData: SportModel[]): void {
    const nameCache = {
      prematch: {
        sports: {},
      },
      special: {
        sports: {},
      },
      outright: {
        sports: {},
      },
      'player-specials': {
        sports: {},
      },
    };

    const idCache = {
      prematch: {
        sports: {},
      },
      special: {
        sports: {},
      },
      outright: {
        sports: {},
      },
      'player-specials': {
        sports: {},
      },
    };

    prematch();
    superSpecials();
    outright();
    playerSpecials();

    const newResolverState = new ResolverState({ idCache, nameCache });

    this.resolverStore.updateCaches(newResolverState);

    function prematch(): void {
      prematchData.forEach(sport => {
        nameCacheParser(sport, 'prematch');
      });
      prematchData.forEach(sport => {
        idCacheParser(sport, 'prematch');
      });
    }

    function superSpecials(): void {
      specialData.forEach(sport => {
        nameCacheParser(sport, 'special');
      });
      specialData.forEach(sport => {
        idCacheParser(sport, 'special');
      });
    }

    function outright(): void {
      outrightData.forEach(sport => {
        nameCacheParser(sport, 'outright');
      });
      outrightData.forEach(sport => {
        idCacheParser(sport, 'outright');
      });
    }

    function playerSpecials(): void {
      playerSpecialsData.forEach(sport => {
        nameCacheParser(sport, 'player-specials');
      });
      playerSpecialsData.forEach(sport => {
        idCacheParser(sport, 'player-specials');
      });
    }

    function idCacheParser(sport: SportModel, key: string): void {
      const id = sport.id;
      idCache[key].sports[id] = {
        name: kebabCase(sport.name),
        categories: {},
      };
      sport.categories.forEach(category => {
        idCache[key].sports[id].categories[category.id] = {
          name: kebabCase(category.name),
          tournaments: {},
        };

        category.tournaments.forEach(tournament => {
          idCache[key].sports[id].categories[category.id].tournaments[tournament.id] = {
            name: kebabCase(tournament.name),
            initRegionId: tournament.initRegionId,
            initAreaId: tournament.initAreaId,
          };
        });
      });
    }

    function nameCacheParser(sport: SportModel, key: string): void {
      nameCache[key].sports[kebabCase(sport.name)] = {
        id: sport.menuUniqueId,
        name: sport.name,
        categories: {},
      };
      sport.categories.forEach(category => {
        nameCache[key].sports[kebabCase(sport.name)].categories[kebabCase(category.name)] = {
          id: category.id,
          name: category.name,
          tournaments: {},
        };

        category.tournaments.forEach(tournament => {
          nameCache[key].sports[kebabCase(sport.name)].categories[kebabCase(category.name)].tournaments[kebabCase(tournament.name)] = {
            id: tournament.id,
            name: tournament.name,
            initRegionId: tournament.initRegionId,
            initAreaId: tournament.initAreaId,
          };
        });
      });
    }
  }

  convertToObjects(data: { eventType: string; category: string; sportName: string; tournament: string }): ResolvedObjectModel {
    if (!this.resolverQuery.nameCache) return;
    const sport = this.resolverQuery.nameCache[data.eventType].sports[data.sportName];
    const category = sport.categories[data.category];
    const tournament = category?.tournaments[data.tournament];

    const resolverObject = new ResolvedObjectModel({
      sport: sport,
      category: category,
      tournament: tournament,
      initRegionId: tournament?.initRegionId,
      initAreaId: tournament?.initAreaId,
    });
    return resolverObject;
  }

  convertToIds(data: { eventType: string; category: string; sport: string; tournament: string }): ResolvedModel {
    let type = data.eventType;
    if (data.eventType === 'special' && data.sport === 'player-specials') {
      type = 'player-specials';
    }
    if (!this.resolverQuery.nameCache) {
      return;
    }

    const sport = this.resolverQuery.nameCache[type].sports[data.sport];

    if (!sport) {
      return;
    }

    const category = sport.categories[data.category];
    if (!category) {
      return;
    }

    const tournament = category.tournaments[data.tournament];
    if (!tournament) {
      return;
    }

    const resolverIds = new ResolvedModel({
      sport: sport.id,
      category: category.id,
      tournament: tournament.id,
      initRegionId: tournament.initRegionId,
      initAreaId: tournament.initAreaId,
    });

    return resolverIds;
  }

  convertToNames(data: any, eventType: EventType = 'prematch'): ResolvedModel {
    let type = eventType;
    if (eventType === 'special' && data.sportId === '9_0') {
      type = 'player-specials';
    }

    if (!this.resolverQuery.idCache) {
      return;
    }

    const sport = this.resolverQuery.idCache[type].sports[data.sportId];
    if (!sport) {
      return;
    }

    const category = sport.categories[data.categoryId];
    if (!category) {
      return;
    }

    const tournament = category.tournaments[data.tournamentId];
    if (!tournament) {
      return;
    }

    const resolvedNames = new ResolvedModel({
      sport: sport.name,
      category: category.name,
      tournament: tournament.name,
      initRegionId: tournament.initRegionId,
      initAreaId: tournament.initAreaId,
    });

    return resolvedNames;
  }

  convertCategoryToName(sportId: any, categoryId: number, eventType: EventType = 'prematch'): string {
    let type = eventType;
    if (eventType === 'special' && sportId === '9_0') {
      type = 'player-specials';
    }

    if (!this.resolverQuery.idCache) {
      return;
    }

    const sport = this.resolverQuery.idCache[type].sports[sportId];
    if (!sport) {
      return;
    }

    const category = sport.categories[categoryId];
    if (!category) {
      return;
    }

    return category.name;
  }

  bruteForceResolveByTournamentId(tournamentId: number, eventType: EventType = 'prematch', menuUniqueId: string = ''): any {
    let type = eventType;

    if (eventType === 'special' && this.sportQuery.selectedQuicklink.type === Quicklinks.PlayerSpecials) {
      type = 'player-specials';
    }

    const keys = Object.keys(this.resolverQuery.idCache[type].sports);

    for (const key of keys) {
      if (key === menuUniqueId || isNull(menuUniqueId) || menuUniqueId === '') {
        const sport = this.resolverQuery.idCache[type].sports[key];
        const categoryKeys = Object.keys(sport.categories);

        for (const categoryKey of categoryKeys) {
          const category = this.resolverQuery.idCache[type].sports[key].categories[categoryKey];
          const tournamentKeys = Object.keys(category.tournaments);

          for (const tournamentKey of tournamentKeys) {
            if (tournamentKey === tournamentId.toString()) {
              return {
                sportId: key,
                categoryId: categoryKey,
                tournamentId: tournamentKey,
              };
            }
          }
        }
      }
    }
  }
}
