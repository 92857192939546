import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { ID } from '@datorama/akita';
import { Subject } from 'rxjs';
import { ApplicationService } from 'src/app/core/services/application.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { CashoutQuery } from 'src/app/core/state/cashout/cashout.query';
import { CashoutStore } from 'src/app/core/state/cashout/cashout.store';
import { EvaluationQuery } from 'src/app/core/state/evaluation/evaluation.query';
import { MyBetsQuery } from 'src/app/modules/my-bets/state/my-bets.query';
import { expandCollapse } from 'src/app/shared/animations';
import { BetFinalState, CouponStatus } from 'src/app/shared/models/coupon-details.model';
import { RecentBetModel } from 'src/app/modules/my-bets/models/my-bets.model';
import { MyBetsService } from 'src/app/modules/my-bets/services/my-bets.service';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { BetsTab } from 'src/app/modules/my-bets/models/my-bets-enums.model';
import { ProductType } from 'src/app/shared/models/product.model';
import { CouponType } from 'clientside-coupon';
import { first } from 'rxjs/operators';
import { JackpotEngineService } from 'src/app/core/services/jackpot-engine.service';

@Component({
  selector: 'my-bets-recent-bets',
  templateUrl: './recent-bets.component.html',
  styleUrls: ['./recent-bets.component.scss'],
  animations: [expandCollapse()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecentBetsComponent implements OnInit, OnDestroy {
  @Input() bets: RecentBetModel[];
  @Input() loading: boolean;
  @Input() isSettled: boolean = false;
  @Input() productTabSelected: ProductType;
  @Input() autoExpandCouponCodes: string[] = [];
  @Input() isVirtualsInstantLeague: boolean = false;

  couponType: typeof CouponType = CouponType;
  productType: typeof ProductType = ProductType;

  readonly betFinalState = BetFinalState;
  readonly couponStatus = CouponStatus;
  readonly betsTab = BetsTab;
  selectedCouponStatus: CouponStatus = CouponStatus.Settled;

  filterBets: string = '';
  taxPercentageToShow = this.appConfigService.get('sports').coupon.stakeTaxPercentageToShow;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    readonly accountQuery: AccountQuery,
    readonly applicationQuery: ApplicationQuery,
    readonly cashoutStore: CashoutStore,
    readonly evaluationQuery: EvaluationQuery,
    readonly myBetsQuery: MyBetsQuery,
    readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    private readonly applicationService: ApplicationService,
    private readonly cashoutQuery: CashoutQuery,
    private readonly myBetsService: MyBetsService,
    private readonly router: Router,
    private readonly appConfigService: AppConfigService,
    private readonly jackpotEngineService: JackpotEngineService
  ) {}

  ngOnInit(): void {
    // Get Jackpot Engine Mapping from CMS
    this.jackpotEngineService.getJPEngineMapping().subscribe();
  }

  getBetDetails(item: RecentBetModel): void {
    const cashoutRequested = this.cashoutQuery.isInPendingList(undefined, item.couponCode);

    this.cashoutStore.cashoutRequested(cashoutRequested);

    if (!item.betDetails) {
      this.myBetsService.getBetDetails(item).subscribe();
    } else {
      this.myBetsService.toggleCollapsed(item);
    }
  }

  toggleBetInfo(item: RecentBetModel): void {
    this.myBetsService.toggleBetInfoCollapsed(item);
  }

  navigateBetList(): void {
    switch (this.productTabSelected) {
      case ProductType.Virtuals:
        // check if the site is being embedded as an iframe in another site
        this.applicationQuery.embedConfig$.pipe(first()).subscribe(embedConfig => {
          if (embedConfig && parent) {
            parent.postMessage(
              {
                source: 'BetkingMobile',
                type: 'bv.embedEvent',
                payload: {
                  key: 'loadBetSearch',
                },
              },
              '*'
            );
          } else {
            this.applicationService.navigateTo('/account/virtuals-bet-search');
          }
        });
        break;
      case ProductType.SportsBook:
      default:
        this.applicationService.navigateTo('/account/bet-search');
        break;
    }
  }

  navigateToBetDetails(bet: RecentBetModel): void {
    let couponCode = bet.couponCode;

    if (!this.isVirtualsInstantLeague && bet.userId !== this.accountQuery.userData.id.toString()) {
      couponCode = [couponCode, bet.userId].join(',');
    }

    const url = `/account/bet-search/coupon-details/${couponCode}`;
    this.navigateToRoute(url);
  }

  navigateToRoute(route: string): void {
    if (route) {
      this.router.navigate([route]);
      this.applicationService.closeAnySlideUps();
    }
  }

  myBetsTrackBy(index: number, item: RecentBetModel): ID {
    return item.couponCode;
  }

  indexTrackBy(index: number): number {
    return index;
  }

  isRecentBetRebetEnabled(item: RecentBetModel): boolean {
    return this.myBetsService.isRecentBetRebetEnabled(item);
  }

  getCouponType(couponItem: any, couponTypeId: CouponType): string {
    switch (couponTypeId) {
      case CouponType.Single: {
        return $localize`Single`;
      }
      case CouponType.Multiple: {
        return $localize`Multiple`;
      }
      case CouponType.System: {
        return $localize`Combinations`;
      }
      case CouponType.Flexicut: {
        return $localize`Flexicut`;
      }
      default:
        return $localize`Unknown`;
    }
  }

  autoExpandCoupon(couponCode: string): boolean {
    return this.autoExpandCouponCodes?.indexOf(couponCode) > -1;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
