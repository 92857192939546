import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { CouponStore } from 'src/app/core/state/coupon/coupon.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { BookBetModel } from 'src/app/shared/models/book-bet.model';
import { APIService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class BookBetService {
  constructor(
    private readonly apiService: APIService,

    private readonly notificationService: NotificationService,
    private readonly couponService: CouponService,
    private readonly couponStore: CouponStore
  ) {}

  getBookBet(bookCode: string, language: string = 'en'): Observable<void> {
    if (!bookCode) {
      return;
    }

    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    return this.apiService.get<any>(APIType.Sportsbook, `bet/Booked/${bookCode}/${language}`, apiSettings).pipe(
      first(),
      map(responseData => {
        if (!responseData) {
          return;
        }

        const removedEvents: string[] = [];

        if (responseData.RemovedEvents) {
          responseData.RemovedEvents.forEach(eventName => {
            removedEvents.push(eventName);
          });
        }

        this.handleBookBetResponse(
          new BookBetModel({
            responseStatus: responseData.ResponseStatus,
            bookCode: bookCode,
            availableEventCount: responseData.AvailableEventCount,
            bookedCoupon: responseData.BookedCoupon,
            originalEventCount: responseData.OriginalEventCount,
            removedEvents: removedEvents,
            hasExpiredEvents: responseData.ResponseStatus === 404 && responseData.BookedCoupon !== null && removedEvents.length > 0,
          })
        );
      })
    );
  }

  loadBookedCoupon(res: BookBetModel): void {
    if (!res.bookedCoupon) {
      return;
    }

    this.couponService.updateCoupon(res.bookedCoupon);
    this.couponService.updateCouponSetting('bookedCouponCode', res.bookCode);
  }

  clearData(): void {
    this.couponStore.clearBookedBetData();
  }

  private handleBookBetResponse(res: BookBetModel): void {
    this.couponStore.updateBookedBetData(res);
    if (res.responseStatus === 1 && res.bookedCoupon !== null && !res.hasExpiredEvents) {
      // all events are not expired
      this.loadBookedCoupon(res);
    } else if (res.responseStatus === 405) {
      // all events are expired
      this.notificationService.showErrorNotification($localize`Booking code is no longer available. All events are expired.`);
    } else if (!res.hasExpiredEvents) {
      this.notificationService.showErrorNotification($localize`Invalid Code!`);
    }
  }
}
