<ng-container *ngIf="odd && odd.enabled; else missingOdd">
  <div class="odd" [@oddChange]="odd.value">
    <span class="odds-boost-badge" *ngIf="odd?.isBoosted && !betBuilderBoosted"></span>
    <div *ngIf="betBuilderBoosted; else default" class="odd-value">
      <span class="old-odd-value">
        {{ odd.unboostedValue | formatOdd }}
      </span>
      <div class="boosted-badge odds-boost-badge"></div>
      {{ odd.value | formatOdd }}
    </div>
    <ng-template #default>
      <div class="odd-value" [class.boosted]="odd.isBoosted">
        {{ odd.value | formatOdd }}
        <span class="old-odd-value" *ngIf="odd.isBoosted">
          <br>{{ odd.unboostedValue | formatOdd }}
        </span>
      </div>
    </ng-template>
    <ng-container *ngIf="isLive">
      <div class="animation-helper">
        {{ odd.value | formatOdd }}
      </div>
      <div class="increased"></div>
      <div class="decreased"></div>
    </ng-container>
  </div>
</ng-container>

<ng-template #missingOdd>
  <span *ngIf="!odd || odd.value === undefined" i18n="Missing odd indicator">-</span>
  <i *ngIf="odd && odd.value !== undefined" class="fa fa-lock"></i>
</ng-template>
