import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { BetCouponGlobalVariable, CouponType } from 'clientside-coupon';
import { InstantCouponStore } from 'src/app/core/state/instant-coupon/instant-coupon.store';
import { InstantCoupon, InstantCouponState } from 'src/app/shared/models/instant-coupon.model';
import { SportModel } from 'src/app/shared/models/sport.model';

@Injectable({ providedIn: 'root' })
export class InstantCouponQuery extends Query<InstantCouponState> {
  hasCouponSelections$ = this.select(state => state.couponData && state.couponData.Odds.length > 0);
  couponData$ = this.select(state => state.couponData);
  selections$ = this.select(state => state.selections);
  couponDataFiltered$ = this.select(state => state.couponData).pipe(
    map(couponData => {
      if (couponData === null || couponData === undefined || couponData.Odds.length <= 0) {
        return undefined;
      }
      return couponData;
    })
  );

  groupingsTabSelected$ = this.select(state => state.groupingsTabSelected);
  couponSelectionIds$ = this.select(state => (state.couponData ? state.couponData.Odds.map(odd => odd.SelectionId) : [])); //
  minBetStake$ = this.select(state => (state.globalVariables ? state.globalVariables.MinBetStake : false));
  minGroupingBetStake$ = this.select(state => state.globalVariables?.MinGroupingsBetStake ?? 0);
  minTotalStake$ = this.select(state => {
    if (!state.couponData) {
      return 0;
    }
    const minBetStake = state.globalVariables?.MinBetStake ?? 0;
    const minGroupingBetStake = state.globalVariables?.MinGroupingsBetStake ?? 0;
    const minCombinationStake = state.couponData.TotalCombinations * minGroupingBetStake;
    return Math.max(minCombinationStake, minBetStake);
  });
  maxTotalStake$ = this.select(state => {
    if (!state.couponData) {
      return 0;
    }
    const maxBetStake = state.globalVariables?.MaxBetStake ?? 0;
    return maxBetStake;
  });
  isCouponSend$ = this.select(state => state.isCouponSend);
  couponTotalOdds$ = this.select(state => {
    if (state.couponData) {
      return state.couponData.CouponType === CouponType.System ? state.couponData.MaxOdd : state.couponData.TotalOdds;
    } else {
      return 0;
    }
  });
  stakeChanged$ = this.select(state => state.ui.stakeChanged);

  constructor(protected store: InstantCouponStore) {
    super(store);
  }

  get couponData(): InstantCoupon {
    const couponData: InstantCoupon = this.getValue().couponData;
    if (couponData === null || couponData === undefined || couponData.Odds.length <= 0) {
      return undefined;
    }
    return couponData;
  }

  get globalVariables(): BetCouponGlobalVariable {
    return this.getValue().globalVariables;
  }

  get groupingsTabSelected(): any {
    return this.getValue().groupingsTabSelected;
  }

  get leagueSwitchContent(): any {
    return this.getValue().leagueSwitchContent;
  }

  get selections(): SportModel[] {
    return this.getValue().selections;
  }

  get couponInitialized(): boolean {
    return this.getValue().couponInitialized;
  }

  get stakeChanged(): boolean {
    return this.getValue().ui.stakeChanged;
  }
}
