import { Injectable, OnDestroy } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map, mergeMap, tap, filter, takeUntil } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { CashoutService } from 'src/app/core/services/cashout.service';
import { CashoutStore } from 'src/app/core/state/cashout/cashout.store';
import { CouponDetailsStore } from 'src/app/core/state/coupon-details/coupon-details.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { CashoutModel, CashoutSource } from 'src/app/shared/models/cashout.model';
import {
  BetFinalState,
  CouponDetailsGroupModel,
  CouponDetailsModel,
  CouponDetailsOddModel,
  CouponDetailsUIState,
  CouponStatus,
  OddResultsModel,
} from 'src/app/shared/models/coupon-details.model';
import {
  BetLiveDetailsModel,
  EventOddsModel,
  RecentBetModel,
  SportVirtualBetDetailsModel,
  SportVirtualEventModel,
} from 'src/app/modules/my-bets/models/my-bets.model';
import { guid } from '@datorama/akita';
import { RecentBetsStatus } from 'src/app/modules/my-bets/models/my-bets-enums.model';
import { CouponDetailsQuery } from 'src/app/core/state/coupon-details/coupon-details.query';
import { LanguageService } from 'src/app/core/services/language.service';
import { MyBetsLiveService } from 'src/app/modules/my-bets/services/my-bets-live-service';
import { ProductType, VirtualsLeagueType, VirtualsProductType } from 'src/app/shared/models/product.model';
import { determineCouponProduct } from 'src/app/shared/utils/determine-coupon-product';
import { VirtualsInstantService } from 'src/app/core/services/virtuals-instant.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ParseCouponDetailsService } from './parse-coupon-details.service';

@Injectable({
  providedIn: 'root',
})
export class CouponDetailsService implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly apiService: APIService,
    private readonly appConfigService: AppConfigService,
    private readonly accountQuery: AccountQuery,
    private readonly cashoutService: CashoutService,
    private readonly cashoutStore: CashoutStore,
    private readonly couponDetailsQuery: CouponDetailsQuery,
    private readonly couponDetailsStore: CouponDetailsStore,
    private readonly languageService: LanguageService,
    private readonly myBetsLiveService: MyBetsLiveService,
    private readonly parseCouponDetailsService: ParseCouponDetailsService,
    private readonly virtualsInstantService: VirtualsInstantService
  ) {
    this.handleLivePolling();
  }

  updateCouponDetailsUI(ui: CouponDetailsUIState): void {
    this.couponDetailsStore.updateUI(ui);
  }

  clearCouponDetailsData(): void {
    this.couponDetailsStore.updateCouponDetailsData(undefined);
  }

  getCouponDetails(couponCode: string, noCheck: boolean = false, language: string = 'en'): Observable<void> {
    if (!couponCode) {
      return undefined;
    }

    const couponCodeArr = couponCode.split(',');
    const code = couponCodeArr[0];
    let inBehalfOf: string;
    if (couponCodeArr.length > 1) {
      inBehalfOf = couponCodeArr[1];
    }

    const apiSettings = new APISettings();
    const uiUpdate = new CouponDetailsUIState({ isLoading: true });

    this.updateCouponDetailsUI(uiUpdate);

    const couponProduct = determineCouponProduct(code);
    let apiType: APIType;
    let couponCheckByCodeApi: string;

    if (noCheck) {
      if (couponProduct.productType === ProductType.Virtuals && couponProduct.virtualsLeagueType === VirtualsLeagueType.Scheduled) {
        couponCheckByCodeApi = `api/virtuals/coupons/noCheck/byCode/${code}/language/${language}`;
        apiType = APIType.VirtualsBetSearch;
      } else if (couponProduct.productType === ProductType.SportsBook) {
        couponCheckByCodeApi = `betsearch/noCheck/allByCode/${code}/language/${language}`;
        apiType = APIType.Sportsbook;
      }
      apiSettings.noAuthToken = true;
    } else {
      if (couponProduct.productType === ProductType.Virtuals && couponProduct.virtualsLeagueType === VirtualsLeagueType.Scheduled) {
        couponCheckByCodeApi = `api/virtuals/coupons/byCode/${code}`; // TODO: language?
        apiType = APIType.VirtualsBetSearch;
      } else if (couponProduct.productType === ProductType.SportsBook) {
        couponCheckByCodeApi = `betsearch/allByCodeWithSettlementDate/${code}/language/${language}`;
        apiType = APIType.Sportsbook;
      }

      if (inBehalfOf) {
        apiSettings.inBehalfOf = inBehalfOf;
      }
    }

    return this.apiService.get<any>(apiType, couponCheckByCodeApi, apiSettings).pipe(
      mergeMap(betDetailsData => {
        if (!betDetailsData) {
          return new Observable<void>();
        }

        if (!betDetailsData.CouponCode) {
          const uiUpdate2 = new CouponDetailsUIState({ wrongCouponCode: true, isLoading: false });
          this.updateCouponDetailsUI(uiUpdate2);
          return new Observable<void>();
        } else if (betDetailsData.CouponCode) {
          const uiUpdate2 = new CouponDetailsUIState({ wrongCouponCode: false, isLoading: false });
          this.updateCouponDetailsUI(uiUpdate2);
        }

        let couponStatus: CouponStatus;
        switch (betDetailsData.CouponStatusId) {
          case 1:
            couponStatus = CouponStatus.Running;
            break;
          case 2:
            couponStatus = CouponStatus.Lost;
            break;
          case 3:
            couponStatus = CouponStatus.Won;
            break;
          case 4:
            couponStatus = CouponStatus.Cancelled;
            break;
          case 5:
            couponStatus = CouponStatus.SystemEvaluation;
            break;
          default:
            couponStatus = CouponStatus.Unknown;
            break;
        }

        const rebetEnabled =
          betDetailsData.Product !== VirtualsProductType.Tournament && betDetailsData.Odds.some(odd => odd.Result === 'Unset');

        const couponDetails = new CouponDetailsModel({
          betFinalState: betDetailsData.BetFinalState,
          couponCode: betDetailsData.CouponCode,
          couponDate: betDetailsData.CouponDate,
          couponStatus: couponStatus,
          couponType: betDetailsData.CouponType,
          couponTypeId: betDetailsData.CouponTypeId,
          currencySymbol: betDetailsData.Currency.CurrencySymbol,
          groups: [],
          jackpotId: betDetailsData.JackpotId,
          jackpotWinnings: betDetailsData.JP,
          maxBonus: betDetailsData.MaxBonus,
          maxBonusPerc: betDetailsData.MaxBonusPerc,
          maxOdd: betDetailsData.MaxOdd,
          maxPotWin: parseFloat(betDetailsData.NetStakeMaxWin) + parseFloat(betDetailsData.MaxBonus),
          maxPotWinNet: betDetailsData.MaxWinNet,
          minWithholdingTax: betDetailsData.MinWithholdingTax,
          maxWithholdingTax: betDetailsData.MaxWithholdingTax,
          maxWin: betDetailsData.MaxWin,
          minBonus: betDetailsData.MinBonus,
          minBonusPerc: betDetailsData.MinBonusPerc,
          minOdd: betDetailsData.MinOdd,
          minWin: betDetailsData.MinWin,
          minWinNet: betDetailsData.MinWinNet,
          netStakeMaxWin: betDetailsData.NetStakeMaxWin,
          netStakeMinWin: betDetailsData.NetStakeMinWin,
          odds: [],
          paymentDate: betDetailsData.PaymentDate,
          rePrint: betDetailsData.IsPrinted,
          rebetEnabled,
          stake: betDetailsData.StakeGross,
          stakeNet: betDetailsData.Stake,
          stakeTax: betDetailsData.TurnoverTax,
          totalCombinations: betDetailsData.TotalCombinations,
          totalOdds: betDetailsData.TotalOdds,
          userId: betDetailsData.UserId,
          userName: betDetailsData.UserName,
          won: betDetailsData.Won,
          wonTax: betDetailsData.TotalTaxed,
          selectionCount: betDetailsData.SelectionCount,
          freebetsVoucher: betDetailsData.BetDetails?.FreeBetDetails && {
            code: betDetailsData.BetDetails.FreeBetDetails.Code,
            name: betDetailsData.BetDetails.FreeBetDetails.Name,
            type: betDetailsData.BetDetails.FreeBetDetails.Type,
          },
          flexicutDetails: betDetailsData.BetDetails?.FlexiCutDetails && {
            Cut: betDetailsData.BetDetails.FlexiCutDetails.Cut,
          },
        });

        betDetailsData.Odds.forEach(odd => {
          const halftimeScore = odd.Results.filter(oddResults => oddResults.Family === 'HT');
          const fulltimeScore = odd.Results.filter(oddResults => oddResults.Family === 'FT');
          let results = {};
          let batch = [];
          for (const result of odd.Results) {
            batch.push(
              new OddResultsModel({
                family: result.Family,
                symbol: result.Symbol,
                value: result.Value,
              })
            );
            if (batch.length === 2) {
              results[batch[0].family.toLowerCase()] = batch;
              batch = [];
            }
          }

          if (!Object.keys(results).length) {
            results = undefined;
          }

          couponDetails.odds.push(
            new CouponDetailsOddModel({
              championship: odd.Championship,
              eventId: odd.IDEvent,
              orderId: odd.IDOrder,
              sportName: odd.SportName,
              categoryName: odd.CategoryName,
              tournamentName: odd.Championship,
              roundNumber: odd.RoundNumber,
              eventCategory: odd.EventCategory,
              eventDate: odd.EventDate,
              eventName: odd.EventName,
              homeTeamName: odd.HomeTeam,
              awayTeamName: odd.AwayTeam,
              marketOutright: odd.MarketOutright,
              isGoalScorer: odd.IsGoalScorer,
              isBanker: odd.FixedOdd,
              marketId: odd.IDMarketType,
              marketName: odd.MarketName,
              selectionName: odd.SelectionName,
              oddValue: odd.OddValue,
              unboostedOddValue: odd.UnboostedOddValue,
              leagueNo: odd.LeagueNo,
              results,
              resultStatus: odd.Result,
              resultStatusId: odd.Win,
              resultHTScore:
                halftimeScore.length !== 0
                  ? {
                      teamOne: halftimeScore.find(halfTimeScore => halfTimeScore.Symbol === 'HT1').Value,
                      teamTwo: halftimeScore.find(halfTimeScore => halfTimeScore.Symbol === 'HT2').Value,
                    }
                  : undefined,
              resultFTScore:
                fulltimeScore.length !== 0
                  ? {
                      teamOne: fulltimeScore.find(fullTimeScore => fullTimeScore.Symbol === 'FT1').Value,
                      teamTwo: fulltimeScore.find(fullTimeScore => fullTimeScore.Symbol === 'FT2').Value,
                    }
                  : undefined,
            })
          );
        });

        betDetailsData.Groupings.forEach(group => {
          couponDetails.groups.push(
            new CouponDetailsGroupModel({
              combinations: group.Combinations,
              grouping: group.Grouping,
              maxBonus: group.MaxBonus,
              maxWin: group.MaxWin,
              minBonus: group.MinBonus,
              minWin: group.MinWin,
              netStakeMaxWin: group.netStakeMaxWin,
              netStakeMinWin: group.NetStakeMinWin,
              stake: group.Stake,
              stakeNet: group.NetStake,
              stakeTax: group.TurnoverTax,
            })
          );
        });
        this.couponDetailsStore.updateCouponDetailsData(couponDetails);

        const uiUpdate2 = new CouponDetailsUIState({ isLoading: false });
        this.updateCouponDetailsUI(uiUpdate2);

        // Cashout is not implemented for virtuals yet
        if (this.accountQuery.isAuthenticated) {
          const checkToPerformCall = this.appConfigService.get('sports').freebets.allowCashout
            ? couponProduct.productType !== ProductType.Virtuals && couponDetails.betFinalState === BetFinalState.Placed
            : couponProduct.productType !== ProductType.Virtuals &&
              couponDetails.betFinalState === BetFinalState.Placed &&
              !couponDetails.hasFreebetsVoucher &&
              !couponDetails.isFlexicut;
          if (checkToPerformCall) {
            return this.apiService.get<any>(APIType.SportsbookCashout, `cashout/cashoutvalue_new/${betDetailsData.CouponCode}`).pipe(
              map(cashoutData => {
                if (cashoutData && cashoutData.length > 0) {
                  const cashout: CashoutModel = new CashoutModel({
                    betCashout: this.cashoutService.parseBetCashoutResponse(cashoutData[0]),
                    betFinalState: betDetailsData.BetFinalState,
                    cashoutSource: CashoutSource.CouponDetails,
                    couponCode: betDetailsData.CouponCode,
                    userId: betDetailsData.UserId,
                  });
                  this.cashoutStore.addCashoutData(cashout);
                }
              })
            );
          } else {
            return new Observable<void>();
          }
        } else {
          return new Observable<void>();
        }
      })
    );
  }

  getInstantCouponDetails(couponCode: string): Observable<void> {
    this.couponDetailsStore.updateCouponDetailsData(undefined);
    if (!couponCode) {
      return;
    }

    this.updateCouponDetailsUI(new CouponDetailsUIState({ isLoading: true }));
    const getCouponDetails$ = this.virtualsInstantService
      .getDataFromGR('/tickets/findById', { ticketId: couponCode })
      .pipe(takeUntil(this.destroy$));

    return forkJoin([this.virtualsInstantService.getInstantLeagueMarketMapping(), getCouponDetails$]).pipe(
      tap(() => this.updateCouponDetailsUI(new CouponDetailsUIState({ isLoading: false }))),
      map(([, couponDetailsRes]) => {
        if (couponDetailsRes?.length) {
          const parsedCoupon = this.parseCouponDetailsService.parseVirtualsInstantCoupon(couponDetailsRes[0]);
          this.couponDetailsStore.updateCouponDetailsData(parsedCoupon);
        } else {
          throw new Error(couponDetailsRes); // Throw an error so it's caught by the caller and a message is shown to users
        }
      }),
      takeUntil(this.destroy$)
    );
  }

  clearCashouts(): void {
    this.cashoutStore.remove(entity => entity.cashoutSource === CashoutSource.CouponDetails);
  }

  getCoupon(recentBet: RecentBetModel): CouponDetailsModel {
    return new CouponDetailsModel({
      betFinalState: recentBet.betFinalState,
    });
  }

  mapToRecentBetsModel(details: CouponDetailsModel): RecentBetModel {
    const mapStatuses = (status: number) => {
      switch (status) {
        case -2:
          return RecentBetsStatus.Running;
        case -1:
          return RecentBetsStatus.Cancelled;
        case 0:
          return RecentBetsStatus.Lost;
        case 1:
          return RecentBetsStatus.Won;
        case 4:
          return RecentBetsStatus.PartiallyWon;
        default:
          return -99;
      }
    };

    const groupOddsWithSameEventAndMarket = () => {
      const betEvents: SportVirtualEventModel[] = [];

      const eventsMap = new Map<string, { event: CouponDetailsOddModel; odds: EventOddsModel[] }>();
      details.odds.forEach(odd => {
        const oddDetails: EventOddsModel = {
          marketName: odd.marketName,
          selectionName: odd.selectionName,
          oddStatusId: mapStatuses(odd.resultStatusId),
          isBanker: odd.isBanker,
          oddValue: odd.oddValue,
          unboostedOddValue: odd.unboostedOddValue,
          isBoosted: odd.isBoosted,
          marketTypeId: parseInt(odd.marketId, 10),
          isBetBuilder: odd.isBetBuilder,
        };

        const mapKey = `${odd.eventId}_${odd.marketName}`;

        const foundEventOdds = eventsMap.has(mapKey) ? eventsMap.get(mapKey).odds : [];

        foundEventOdds.push(oddDetails);
        eventsMap.set(mapKey, { event: odd, odds: foundEventOdds });
      });

      eventsMap.forEach(value => {
        const event: SportVirtualEventModel = {
          championship: value.event.championship,
          categoryName: value.event.categoryName,
          eventId: value.event.eventId,
          sportId: value.event.sportName,
          eventName: value.event.eventName,
          homeTeamName: value.event.homeTeamName,
          awayTeamName: value.event.awayTeamName,
          marketOutright: value.event.marketOutright,
          isGoalScorer: value.event.isGoalScorer,
          eventDate: new Date(value.event.eventDate),
          eventStatusId: mapStatuses(value.event.resultStatusId),
          isLive: value.event.eventCategory === 'L',
          halfTimeScore: value.event.resultHTScore
            ? `${value.event.resultHTScore.teamOne}-${value.event.resultHTScore.teamTwo}`
            : undefined,
          fullTimeScore: value.event.resultFTScore
            ? `${value.event.resultFTScore.teamOne}-${value.event.resultFTScore.teamTwo}`
            : undefined,
          odds: value.odds,
          result: value.event.resultStatus,
        };

        if (value.odds.length > 1 && value.odds.some(odd => odd.oddStatusId === 1)) {
          // if any odd is won, set the main state as won
          event.eventStatusId = 1;
        }

        betEvents.push(event);
      });
      return betEvents;
    };

    return new RecentBetModel({
      betDetails: {
        totalOdds: details.totalOdds,
        totalCombinations: details.totalCombinations,
        netStakeMinWin: details.netStakeMinWin,
        netStakeMaxWin: details.netStakeMaxWin,
        minWin: details.minWin,
        maxWin: details.maxWin,
        maxWinNet: details.maxPotWinNet,
        minWithholdingTax: details.minWithholdingTax,
        maxWithholdingTax: details.maxWithholdingTax,
        minBonus: details.minBonus,
        maxBonus: details.maxBonus,
        minPercentageBonus: details.minBonusPerc,
        maxPercentageBonus: details.maxBonusPerc,
        turnoverTax: details.stakeTax,
        events: groupOddsWithSameEventAndMarket(),
        won: details.won,
        wonTax: details.wonTax,
        couponType: details.couponType,
        minOdd: details.minOdd,
        maxOdd: details.maxOdd,
      } as SportVirtualBetDetailsModel,
      betFinalState: details.betFinalState,
      collapsed: false,
      betInfoCollapsed: false,
      couponCode: details.couponCode,
      couponDate: details.couponDate,
      couponType: details.couponType,
      couponStatus: details.couponStatus.toString(),
      couponStatusId: details.couponStatus,
      currencySymbol: details.currencySymbol,
      id: guid(),
      jackpotId: details.jackpotId,
      jackpotWinnings: details.jackpotWinnings,
      stakeGross: details.stake,
      totalOdds: details.totalOdds,
      totalCombinations: details.totalCombinations,
      minOdd: details.minOdd,
      maxOdd: details.maxOdd,
      won: details.won,
      maxWinNet: details.maxPotWinNet,
      userId: details.userId.toString(),
      selectionCount: details.selectionCount,
      freebetsVoucher: details.freebetsVoucher,
      flexicutDetails: details.flexicutDetails,
    });
  }

  private readonly handleLivePolling = () => {
    const updateLiveBetsDetailsFunction = liveBetDetails => {
      this.couponDetailsStore.updateCouponDetailsLiveData(liveBetDetails);
    };

    const getLiveDataCallFunction = () => this.getLiveDataCall();

    this.myBetsLiveService.livePolling(
      getLiveDataCallFunction,
      updateLiveBetsDetailsFunction,
      this.couponDetailsQuery.pollLiveDetailsInterval,
      this.couponDetailsQuery.isLiveDetailsPollingEnabled$,
      // If we have live odd, then we have a live bet
      this.couponDetailsQuery.couponDetails$.pipe(
        filter(coupon => !!coupon),
        map(coupon => (coupon.odds.some(odd => odd.eventCategory === 'L') ? 1 : 0))
      ),
      this.destroy$
    );
  };

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private getLiveDataCall(): Observable<BetLiveDetailsModel[]> {
    const couponProduct = determineCouponProduct(this.couponDetailsQuery.couponDetails.couponCode);
    if (couponProduct.productType === ProductType.SportsBook) {
      return this.apiService
        .get(
          APIType.SportsbookFeed,
          `api/feeds/live/overview/general/${this.languageService.selectedLanguage.language}`,
          new APISettings({
            noAuthToken: true,
          })
        )
        .pipe(map(data => this.myBetsLiveService.parseSportsLiveEvents(data)));
    }
  }
}
