import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { flattenDeep } from 'lodash-es';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { VirtualsStore } from 'src/app/core/state/virtuals/virtuals.store';
import {
  CategoryEventsTiming,
  CategoryEventsTimings,
  VirtualsInfoSection,
  VirtualsInstantLeagueMap,
  VirtualsJackpot,
  VirtualsJackpotBanner,
  VirtualsLobbyContent,
  VirtualsLobbyGame,
  VirtualsLobbySection,
  VirtualsSlideUpType,
  VirtualsState,
} from 'src/app/shared/models/virtuals.model';
import { BreadcrumbNavigation } from 'src/app/shared/models/breadcrumb-navigation';

@Injectable({
  providedIn: 'root',
})
export class VirtualsQuery extends Query<VirtualsState> {
  lobbyContent$ = this.select(state => state.lobbyContent);
  lobbyInfoSections$ = this.select(state => state.lobbyInfoSections);
  groupEntityId$ = this.select(state => state.groupEntityId);
  instantLeagueMap$ = this.select(state => state.instantLeagueMap);
  instantUserData$ = this.select(state => state.instantUserData);
  categoryEventsTimings$ = this.select(s => s.categoryEventsTimings);
  jackpots$ = this.select(s => s.jackpots);
  breadcrumbNavigation$ = this.select(state => state.breadcrumbNavigation);
  betSuccessDialogContent$ = this.select(state => state.betSuccessDialogContent);
  betSuccessDialogVisibility$ = this.select(state => !!state.ui?.betSuccessDialogVisibility);
  showingSlideUps$ = this.select(s => Object.values(s.ui?.slideUps)?.some(value => value));
  showingCouponSlideUp$ = this.select(s => !!s.ui?.slideUps?.[VirtualsSlideUpType.Coupon]);
  showingMyBetsSlideUp$ = this.select(s => !!s.ui?.slideUps?.[VirtualsSlideUpType.MyBets]);

  constructor(
    protected store: VirtualsStore,
    private readonly appConfig: AppConfigService,
    private readonly applicationQuery: ApplicationQuery
  ) {
    super(store);
  }

  get lobbyContent(): VirtualsLobbyContent {
    return this.getValue().lobbyContent;
  }

  get lobbyGames(): VirtualsLobbyGame[] {
    return flattenDeep(this.getValue().lobbyContent?.sections?.map(section => section.subSections?.map(subSection => subSection.games)));
  }

  get lobbyInfoSections(): VirtualsInfoSection[] {
    return this.getValue().lobbyInfoSections;
  }

  get breadcrumbNavigation(): BreadcrumbNavigation {
    return this.getValue().breadcrumbNavigation;
  }

  get groupEntityId(): number {
    return this.getValue().groupEntityId;
  }

  get instantLeagueMap(): VirtualsInstantLeagueMap {
    return this.getValue().instantLeagueMap;
  }

  get instantUserData(): any {
    return this.getValue().instantUserData;
  }

  get categoryEventsTimings(): CategoryEventsTimings {
    return this.getValue().categoryEventsTimings;
  }

  get jackpots(): VirtualsJackpot[] {
    return this.getValue().jackpots;
  }

  get jackpotBanner(): VirtualsJackpotBanner {
    return this.getValue().jackpotBanner;
  }

  get useVirtualsNavbar$(): Observable<boolean> {
    const scheduledConfig = this.appConfig.get('virtuals')?.scheduledLeague.useVirtualsNavbar;
    const instantConfig = this.appConfig.get('virtuals')?.instantLeague.useVirtualsNavbar;

    return combineLatest([
      this.applicationQuery.isVirtuals$,
      this.applicationQuery.isVirtualsScheduled$,
      this.applicationQuery.isVirtualsInstant$,
      this.applicationQuery.embedConfig$,
    ]).pipe(
      map(([isVirtuals, isVirtualsScheduled, isVirtualsInstant, embedConfig]) => {
        if (isVirtuals && embedConfig?.hideNavbar) {
          // the virtuals navbar will always be shown when embedded and hideNavbar is set to true
          return true;
        }
        return isVirtualsScheduled ? scheduledConfig : isVirtualsInstant ? instantConfig : false;
      })
    );
  }

  categoryEventTiming$(categoryId: number | string): Observable<CategoryEventsTiming> {
    return this.categoryEventsTimings$.pipe(
      map(timings => timings?.[categoryId]),
      filter(timing => !!timing?.nextEventDate)
    );
  }

  jackpotById$(jackpotId: number): Observable<VirtualsJackpot> {
    return this.jackpots$.pipe(map(jackpots => jackpots?.find(jackpot => jackpot.id === jackpotId)));
  }

  jackpotByCode$(jackpotCode: VirtualsLobbySection['jackpot']): Observable<VirtualsJackpot> {
    const jackpotConfig = this.appConfig.get('virtuals')?.jackpot;
    let jackpotId: number;
    if (jackpotCode === 'kingmaker') {
      jackpotId = jackpotConfig?.kingmakerJackpotId;
    } else if (jackpotCode === 'duke') {
      jackpotId = jackpotConfig?.dukeJackpotId;
    }

    return this.jackpotById$(jackpotId);
  }
}
