import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil, tap, timeout } from 'rxjs/operators';
import { LanguageService } from 'src/app/core/services/language.service';
import { VirtualsQuery } from 'src/app/core/state/virtuals/virtuals.query';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { VirtualsStore } from 'src/app/core/state/virtuals/virtuals.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import {
  VirtualsBetSuccessDialogModel,
  VirtualsInfoSection,
  VirtualsJackpot,
  VirtualsJackpotBanner,
  VirtualsLatestJackpotWinnersInfo,
} from 'src/app/shared/models/virtuals.model';
import { mapToCamelCase } from 'src/app/shared/utils/map-to-camel-case';
import { BreadcrumbNavigation } from 'src/app/shared/models/breadcrumb-navigation';
import { UserType } from 'src/app/shared/models/account.model';
import { APIService } from './api.service';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class VirtualsService implements OnDestroy {
  private readonly cmsContent: VirtualsBetSuccessDialogModel = {
    header: 'Best of luck!',
    description: `<p> Your bet is placed. </br> You are automatically in with a chance to win one of our Virtual Jackpots!</p>`,
    buttonText: 'CONTINUE BETTING',
  };
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly apiService: APIService,
    private readonly languageService: LanguageService,
    private readonly virtualsStore: VirtualsStore,
    private readonly virtualsQuery: VirtualsQuery,
    private readonly appConfig: AppConfigService,
    private readonly accountQuery: AccountQuery
  ) {}

  get isVirtualsEnabled(): boolean {
    return this.appConfig.get('virtuals')?.enabled;
  }

  get isScheduledLeagueEnabled(): boolean {
    return this.appConfig.get('virtuals')?.scheduledLeague?.enabled;
  }

  get isInstantLeagueEnabled(): boolean {
    return this.appConfig.get('virtuals')?.instantLeague?.enabled;
  }

  getVirtualsGameFrameUrl(clientType: string = 'web'): Observable<any> {
    return this.apiService
      .get(APIType.Platform, `/api/GamingVendors/GlobalBet/GameFrameUrl?globalBetClientType=${clientType}`)
      .pipe(takeUntil(this.destroy$));
  }

  getVirtualsLatestJackpotWinnerSection(): Observable<VirtualsLatestJackpotWinnersInfo> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });
    const language: string = this.languageService.selectedLanguage.locale.toLowerCase();

    return this.apiService
      .get(APIType.CMS, `/Virtuals/GetVirtualsLatestJackpotWinnerSection?language=${language}`, apiSettings)
      .pipe(takeUntil(this.destroy$));
  }

  getGroupEntityId(userId: number): Observable<any> {
    return this.apiService.get(APIType.Platform, `/api/GamingVendors/GoldenRace/GroupEntityID?userID=${userId}`).pipe(
      map(response => {
        if (response && response.Result) {
          this.virtualsStore.updateGroupEntityId(response.Result);
        }
      }),
      takeUntil(this.destroy$)
    );
  }

  getCategoryEventsTimings(categoryIds: (number | string)[]): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    if (!categoryIds?.length) {
      return of({});
    }

    const threshold: number = this.appConfig.get('virtuals')?.eventsTimingsThreshold || 0;
    return this.apiService
      .get(APIType.VirtualsFeed, `api/virtuals/getCategoryEventsTimings/${categoryIds?.join(',')}/${threshold}`, apiSettings)
      .pipe(
        map(mapToCamelCase),
        map(response => {
          if (response?.categoryEventsTimings) {
            this.virtualsStore.updateCategoryEventsTimings(response.categoryEventsTimings);
          } else {
            this.virtualsStore.removeCategoryEventsTimings(categoryIds);
          }
        }),
        catchError(error => {
          this.virtualsStore.removeCategoryEventsTimings(categoryIds);
          return throwError(error);
        }),
        takeUntil(this.destroy$)
      );
  }

  getTournamentsEventsTimings(categoryId: string): Observable<any> {
    if (!categoryId) {
      return of({});
    }

    return this.apiService.get(APIType.BFFGateway, `api/virtuals/v1/football/rounds/${categoryId}?limit=3`).pipe(
      map(response => {
        if (response.data) {
          const upcomingRounds = response.data.filter(round => new Date(round.date).getTime() > Date.now());

          if (upcomingRounds.length >= 2) {
            const [firstRound, secondRound] = upcomingRounds;
            const eventInterval = new Date(secondRound.date).getTime() - new Date(firstRound.date).getTime();

            this.virtualsStore.updateCategoryEventsTimings({
              [categoryId]: {
                eventInterval,
                nextEventDate: firstRound.date,
              },
            });
          }
        } else {
          this.virtualsStore.removeCategoryEventsTimings([categoryId]);
        }
      }),
      catchError(error => {
        this.virtualsStore.removeCategoryEventsTimings([categoryId]);
        return throwError(error);
      }),
      takeUntil(this.destroy$)
    );
  }

  getJackpots(): Observable<VirtualsJackpot[]> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    return this.apiService.get(APIType.KMJackpots, 'api/virtuals/getjackpotvalues', apiSettings).pipe(
      map(mapToCamelCase),
      map(response => {
        const jackpots: VirtualsJackpot[] = [];
        if (response?.jackpots) {
          response.jackpots.forEach(jackpot => {
            jackpots.push({
              id: jackpot.jackpotId,
              name: jackpot.jackpotName,
              value: jackpot.jackpotValue,
              dropFrequency: jackpot.jackpotDropFrequencyInSeconds,
              updateDate: jackpot.updateDate,
            });
          });
          this.virtualsStore.updateJackpots(jackpots);
        }
        return jackpots;
      }),
      takeUntil(this.destroy$)
    );
  }

  getLobbyContent(includeDisabled: boolean = false): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });
    // Retrieve the data only once
    if (this.virtualsQuery.lobbyContent) {
      return of(undefined);
    }

    const language = this.languageService.selectedLanguage.locale.toLowerCase();

    return this.apiService.get(APIType.CMS, `/Virtuals/GetLobby?language=${language}&includeDisabled=${includeDisabled}`, apiSettings).pipe(
      catchError(err => {
        this.setDefaultLobbyContent();
        return throwError(err);
      }),
      map(lobbyContent => {
        if (!lobbyContent || Object.keys(lobbyContent).length === 0) {
          this.setDefaultLobbyContent();
        } else {
          this.virtualsStore.updateLobbyContent(lobbyContent);
        }
      }),
      takeUntil(this.destroy$)
    );
  }

  private setDefaultLobbyContent(): void {
    this.virtualsStore.updateLobbyContent(this.appConfig.get('virtuals').lobbyContentDefaults);
  }

  getLobbyInfoSections(): Observable<VirtualsInfoSection[]> {
    // Retrieve the data only once
    if (this.virtualsQuery.lobbyInfoSections) {
      return of(this.virtualsQuery.lobbyInfoSections);
    }

    return this.apiService
      .gqlQuery<{ virtualsLobbyPage: { data: { attributes: { infoSections: VirtualsInfoSection[] } } } }>(
        `
        query VirtualsLobbyInfoSections($locale: I18NLocaleCode) {
          virtualsLobbyPage(locale: $locale) {
            data {
              attributes {
                infoSections {
                  title
                  content
                  subSections {
                      title
                      expanded
                      content
                  }
                }
              }
            }
          }
        }
        `,
        this.languageService.strapiCMSLocale
      )
      .pipe(
        map(({ data }) => data?.virtualsLobbyPage?.data?.attributes?.infoSections),
        tap(data => {
          !!data && this.virtualsStore.updateLobbyInfoSections(data);
        }),
        takeUntil(this.destroy$)
      );
  }

  getJackpotBanner(): Observable<VirtualsJackpotBanner> {
    const existingBanner = this.virtualsQuery.jackpotBanner;
    if (existingBanner) {
      return of(existingBanner);
    }

    return this.apiService
      .gqlQuery<{ virtualsJackpot: { data: { attributes: { jackpotBanner: VirtualsJackpotBanner } } } }>(
        `
        query VirtualsJackpotBanner($locale: I18NLocaleCode) {
          virtualsJackpot(locale: $locale) {
            data {
              attributes {
                jackpotBanner {
                  jackpots {
                    jackpotId
                  }
                  visibility {
                    lobby
                    games
                    scheduledLeagues
                    instantLeagues
                  }
                  bannerMessage
                }
              }
            }
          }
        }
        `,
        this.languageService.strapiCMSLocale
      )
      .pipe(
        map(({ data }) => data?.virtualsJackpot?.data?.attributes?.jackpotBanner),
        tap(jackpotBanner => {
          if (jackpotBanner) {
            this.virtualsStore.updateJackpotBanner(jackpotBanner);
          }
        }),
        takeUntil(this.destroy$)
      );
  }

  getBreadcrumbNavigation(): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    if (this.virtualsQuery.breadcrumbNavigation) {
      return of(undefined);
    }
    const language = this.languageService.selectedLanguage.locale.toLowerCase();

    return this.apiService.get(APIType.CMS, `/Virtuals/GetBreadcrumbNavigation?language=${language}`, apiSettings).pipe(
      timeout(5000),
      catchError(err => {
        this.setDefaultBreadcrumbNavigation();
        return throwError(err);
      }),
      map((breadcrumbData: BreadcrumbNavigation) => {
        if (!breadcrumbData || !Object.values(breadcrumbData).some(propertyValue => propertyValue)) {
          this.setDefaultBreadcrumbNavigation();
        } else {
          this.virtualsStore.updateBreadcrumbNavigation(breadcrumbData);
        }
      }),
      takeUntil(this.destroy$)
    );
  }

  getSuccessBetDialogContent(): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });
    const language: string = this.languageService.selectedLanguage.locale.toLowerCase();
    this.setDefaultBetSuccessContent();

    return this.apiService.get(APIType.CMS, `/Virtuals/GetSuccessBetDialogContent?language=${language}`, apiSettings).pipe(
      timeout(5000),
      catchError(err => {
        this.setDefaultBetSuccessContent();
        return throwError(err);
      }),
      map((content: VirtualsBetSuccessDialogModel) => {
        if (content || Object.values(content).some(propertyValue => propertyValue)) {
          this.virtualsStore.updateSuccessBetDialogContent(content);
        }
      }),
      takeUntil(this.destroy$)
    );
  }

  isEndpointDisabledForUser(url: string): boolean {
    if (!this.accountQuery.isAuthenticated) {
      return false;
    }

    const virtualsConfig = this.appConfig.get('virtuals');
    const disabledLeaguesForSubAccountPlayers: any[] = virtualsConfig.disabledLeaguesForSubAccountPlayers;
    const disabledGamesForSubAccountPlayers: any[] = virtualsConfig.goldenRace.disabledGamesForSubAccountPlayers;
    const leagueCategoryId: string = url.substring(url.lastIndexOf('/') + 1);
    const isSubAccount: boolean = this.accountQuery.userData?.userTypeCode === UserType.SubAccountPlayer;

    return (
      isSubAccount &&
      (disabledGamesForSubAccountPlayers.includes(leagueCategoryId) || disabledLeaguesForSubAccountPlayers.includes(leagueCategoryId))
    );
  }

  private setDefaultBreadcrumbNavigation(): void {
    this.virtualsStore.updateBreadcrumbNavigation(this.appConfig.get('virtuals').breadcrumbNavigationDefaults);
  }

  private setDefaultBetSuccessContent(): void {
    this.virtualsStore.updateSuccessBetDialogContent(this.cmsContent);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
