<!-- Jackpot strip -->
<div class="jackpot-rotation" *ngIf="(matchedJackpots$ | async)?.length">
  <!-- Content Container -->
  <div class="jackpot-content-container">
    <!-- Main Content Container -->
    <div class="jackpot-main-container-content" *ngIf="(currentJackpot$ | async) as currentJackpot">
      <!-- Dynamic Jackpot Container -->
      <ng-container *ngFor="let jackpot of (matchedJackpots$ | async)">
        <div *ngIf="currentJackpot.id === jackpot.id" class="jackpot-container-content" [ngClass]="currentJackpot.slug"
              data-testid="jackpot-strip-{{ currentJackpot.slug }}-jp" [@slowFadeInOut]>

          <span i18n class="jackpot-name" [ngClass]="currentJackpot.slug"
            data-testid="jackpot-strip-{{ currentJackpot.slug }}-jp-name">
            {{ currentJackpot.name }}
          </span>

          <div class="jackpot-value" data-testid="jackpot-strip-{{ currentJackpot.slug }}-jp-value"
              [ngClass]="currentJackpot.slug">

            <span class="currency" [ngClass]="currentJackpot.slug">
              {{ this.currencyService.getCurrency(this.applicationQuery.currency).symbol }}
            </span>
            <div class="target-value" [ngClass]="currentJackpot.slug">
              {{currentJackpot.value | number:'1.0-0'}}
            </div>
          </div>
        </div>
      </ng-container>
      <span i18n class="jackpot-message" data-testid="jackpot-message-text"
            *ngIf="bannerMessage$ | async as bannerMessage">{{bannerMessage}}</span>
    </div>
    <span class="material-icons jackpot-rotation-info" (click)="navigateToJackpotInfo()"
          data-testid="jackpot-strip-info-btn">{{ 'info' }}</span>
  </div>
</div>
